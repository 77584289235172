import {PhraseSttData, SttDataDto} from "@/features/call2text/types";

export function recognitionDataDto2Model(dto: SttDataDto): PhraseSttData | null {
    if (!dto.alternatives[0]) throw "noAlternatives"
    const phraseData = dto.alternatives[0]
    if (!phraseData.transcript || !phraseData.words) return null

    return {
        start: dto.startTime / 1000,
        end: dto.endTime / 1000,
        channel: dto.channel,
        transcript: phraseData.transcript,
        words: phraseData.words?.map((i) => ({
            word: i.word,
            start: i.startTime / 1000,
            end: i.endTime / 1000
        })) || []
    }
}
