export enum SttState {
    CREATED = 'CREATED',
    TRANSFERED = 'TRANSFERED',
    TRANSFER_FAILED = 'TRANSFER_FAILED',
    UNSUPPORTED = 'UNSUPPORTED',
    ENQUEUED = 'ENQUEUED',
    PROCESSING = 'PROCESSING',
    DONE = 'DONE',
    FAILED = 'FAILED',
}
